import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Link } from "gatsby"

function FooterGreen() {
  const intl = useIntl()
  return (
    <div className="footer-bottom footer-line">
      <div className="container">
        <div className="footer-bottom-in">
          <div className="footer-bottom-text text-white">
            <p>
              {intl.formatMessage({
                id: "footer.copyright",
              })}
            </p>
          </div>
          <div className="footer-bottom-social">
            <ul className="dlab-social-icon dez-border">
              <li>
                <a
                  href="https://www.youtube.com/channel/UCvD1k5epzEmVR40J_KYCinQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fa fa-youtube-play"
                ></a>
              </li>
              <li>
                <a
                  href="https://id.linkedin.com/company/kalbio-global-medika"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fa fa-linkedin"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterGreen
