import React, { Component } from "react"
import { Link } from "gatsby"

import { injectIntl } from "react-intl"
import "../../css/custom-css/custom-footer.css"
import logoLight from "../../images/kalbe-logo.png"
import FooterGreen from "./FooterGreen"

class FooterCream extends Component {
  render() {
    const { formatMessage } = this.props.intl
    const locale =
      this.props.intl.locale !== "en" ? `/${this.props.intl.locale}` : ""
    return (
      <>
        <footer className="site-footer style1">
          <div className="footer-top custom-footer-color">
            <div className="container">
              <div className="footer-content">
                <div className="row justify-content-around">
                  <div className="col-md-4 col-sm-6">
                    <div className="widget widget_about">
                      <h4 className="footer-title">
                        <img
                          className="footer-logo-custom"
                          src={logoLight}
                          alt=""
                        />
                      </h4>
                      <p className="text-black footer-content">
                        Kawasan Industri Delta Silicon 3, Jl Soka
                        <br />
                        Blok F19 No. 002, Cicau - Cikarang Pusat,
                        <br />
                        Kab. Bekasi, Jawa Barat 17530, Indonesia{" "}
                      </p>
                    </div>
                  </div>
                  <div className="about col-md-4 col-sm-6">
                    <div className="widget">
                      <div className="footer-links">
                        <h4 className="footer-title p-t40 text-black">
                          {formatMessage({ id: "header.aboutus" })}
                        </h4>
                        <ul className="list-2 horizontal">
                          <li>
                            <Link to={`${locale}/about`}>
                              {formatMessage({ id: "header.aboutkalbio" })}
                            </Link>
                          </li>
                          <li>
                            <a
                              href="https://www.kalbe.co.id/en"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {formatMessage({ id: "footer.aboutkalbe" })}
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.kg-biologics.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {formatMessage({ id: "footer.aboutkgbio" })}
                            </a>
                          </li>
                          <li>
                            <a
                              href="http://www.innogene-kalbiotech.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {formatMessage({ id: "footer.aboutigk" })}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="our-services col-md-4 col-sm-6">
                    <div className="widget">
                      <div className="footer-links">
                        <h4 className="footer-title p-t40">
                          {formatMessage({ id: "footer.ourservices" })}
                        </h4>
                        <ul className="list-2">
                          <li>
                            <Link
                              to={`${locale}/products-and-services/services/cmo`}
                            >
                              {formatMessage({ id: "header.cmo" })}
                            </Link>
                          </li>
                          <li className="custom">
                            <Link
                              to={`${locale}/products-and-services/services/toll-in-testing`}
                            >
                              {formatMessage({ id: "header.tollintesting" })}
                            </Link>
                          </li>
                          <li className="custom responsiveFooter">
                            <Link
                              to={`${locale}/products-and-services/services/process-analytical-development`}
                            >
                              {formatMessage({
                                id: "header.processandanalyticaldevelopment",
                              })}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FooterGreen />
        </footer>
      </>
    )
  }
}

export default injectIntl(FooterCream)
