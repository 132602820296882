import React, { useState, Component } from "react"
import { useIntl, FormattedMessage, IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { injectIntl } from "react-intl"
import { Link } from "gatsby"
import Sticky from "react-stickynode"
import logo from "../images/kalbe-logo.png"
import "../css/custom-css/custom-header.css"

import Indonesian from "../images/indonesia.png"
import English from "../images/america.png"
import Chinese from "../images/china.png"

const languageName = {
  en: "English",
  id: "Indonesian",
  cn: "中文",
}

const languageIcon = {
  "English": English,
  "Indonesian": Indonesian,
  "中文": Chinese,
}

class Header extends Component {
  componentDidMount() {
    // sidebar open/close

    var btn = document.querySelector(".navicon")
    var nav = document.querySelector(".header-nav")

    function toggleFunc() {
      btn.classList.toggle("open")
      nav.classList.toggle("show")
    }

    btn.addEventListener("click", toggleFunc)

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll(".header-nav > ul > li")
    )
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this)
      })
    }

    function checkLi(current) {
      const active = current.classList.contains("open")
      navUl.forEach(el => el.classList.remove("open"))
      //current.classList.add('open');

      if (active) {
        current.classList.remove("open")
      } else {
        current.classList.add("open")
      }
    }
  }
  render() {
    const { formatMessage } = this.props.intl;
    const locale = this.props.intl.locale !== "en" ? `/${this.props.intl.locale}` : ""
    return (
      <>
        <header className="site-header mo-left header">
          <Sticky innerZ={999} enabled={true}>
            <div className="sticky-header main-bar-wraper navbar-expand-lg">
              <div className="main-bar clearfix ">
                <div className="container clearfix">
                  <div className="logo-header mostion">
                    <Link to={`${locale}/`}>
                      <img src={logo} alt="" />
                    </Link>
                  </div>

                  <button
                    className="navbar-toggler navicon justify-content-end"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>

                  <div
                    className={`header-nav navbar-collapse collapse justify-content-end`}
                    id="navbarNavDropdown"
                  >
                    <div className="logo-header d-md-block d-lg-none">
                      <Link to={"/"}>
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <ul className="nav navbar-nav">

                      {/* index page */}

                      <li
                        className={
                          this.props.indexPage
                            ? "active has-mega-menu homedemo"
                            : "has-mega-menu homedemo"
                        }
                      >
                        {" "}
                        <Link to={`${locale}/`}>
                          {formatMessage({ id: "header.home" })}<i></i>
                        </Link>
                      </li>

                      {/* about us page */}
                      
                      <li
                        className={
                          this.props.aboutPage
                            ? "active"
                            : ""
                        }>
                        <Link to={`${locale}/about`}>
                          {/* {intl.formatMessage({ id: "aboutus" })}<i className="fa fa-chevron-down"></i> */}
                          {formatMessage({ id: "header.aboutus" })}<i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to={`${locale}/about/#kalbio`}>
                              {/* {intl.formatMessage({ id: "aboutkalbio" })} */}
                              {formatMessage({ id: "header.aboutkalbio" })}
                            </Link>
                          </li>
                          <li>
                            <Link to={`${locale}/about/#our-partners`}>
                              {/* {intl.formatMessage({ id: "ourpartners" })} */}
                              {formatMessage({ id: "header.ourpartners" })}
                            </Link>
                          </li>
                          <li>
                            <Link to={`${locale}/about/#qualifications`}>
                              {/* {intl.formatMessage({ id: "qualifications" })} */}
                              {formatMessage({ id: "header.qualifications" })}
                            </Link>
                          </li>
                          <li>
                            <Link to={`${locale}/about/#recognitions`}>
                              {/* {intl.formatMessage({ id: "recognitions" })} */}
                              {formatMessage({ id: "header.recognitions" })}
                            </Link>
                          </li>
                          <li>
                            <Link to={`${locale}/about/#our-leader`}>
                              {/* {intl.formatMessage({ id: "ourleadershipteam" })} */}
                              {formatMessage({ id: "header.ourleadershipteam" })}
                            </Link>
                          </li>
                          <li>
                            <Link to={`${locale}/about/#our-depthead`}>
                              {/* {intl.formatMessage({ id: "ourdepartmentheads" })} */}
                              {formatMessage({ id: "header.ourdepartmentheads" })}
                            </Link>
                          </li>
                          <li>
                            <Link to={`${locale}/about/#our-stakeholders`}>
                              {/* {intl.formatMessage({ id: "ourstakeholders" })} */}
                              {formatMessage({ id: "header.ourstakeholders" })}
                            </Link>
                          </li>
                        </ul>
                      </li>

                      {/* products and services page */}
                      
                      <li
                        className={
                          this.props.page === "productsPage"
                            ? "active has-sub-menu"
                            : "has-sub-menu"
                        }
                      >
                        <Link to={`${locale}/products-and-services`}>
                          {/* {intl.formatMessage({ id: "productsandservices" })} */}
                          {formatMessage({ id: "header.productsandservices" })}
                          <i className="fa fa-chevron-down"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to={`${locale}/products-and-services/services/cmo`}>
                              {/* {intl.formatMessage({ id: "cmo" })} */}
                              {formatMessage({ id: "header.cmo" })}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${locale}/products-and-services/services/toll-in-testing`}
                            >
                              {/* {intl.formatMessage({ id: "tollintesting" })} */}
                              {formatMessage({ id: "header.tollintesting" })}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${locale}/products-and-services/services/process-analytical-development`}
                            >
                              {/* {intl.formatMessage({ id: "processandanalyticaldevelopment" })} */}
                              {formatMessage({ id: "header.processandanalyticaldevelopment" })}
                            </Link>
                          </li>
                        </ul>
                      </li>

                      {/* career page */}
                      
                      <li
                        className={
                          this.props.careerPage
                            ? "active has-mega-menu"
                            : "has-mega-menu"
                        }
                      >
                        <Link to={`${locale}/career-page`}>
                          {/* {intl.formatMessage({ id: "career" })}<i></i> */}
                          {formatMessage({ id: "header.career" })}<i></i>
                        </Link>
                      </li>

                      {/* news event page */}
                      
                      <li
                        className={
                          this.props.newsEventsPage
                            ? "active has-mega-menu"
                            : "has-mega-menu"
                        }
                      >
                        {" "}
                        <Link to={`${locale}/news-page/news-event`}>
                          {/* {intl.formatMessage({ id: "newsandevents" })}<i></i> */}
                          {formatMessage({ id: "header.newsandevents" })}<i></i>
                        </Link>
                      </li>

                      {/* contact us page */}

                      <li
                        className={
                          this.props.page === "contactUsPage"
                            ? "active has-mega-menu"
                            : "has-mega-menu"
                        }
                      >
                        <Link to={`${locale}/contact-us`}>
                          {/* {intl.formatMessage({ id: "contactus" })} */}
                          {formatMessage({ id: "header.contactus" })}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://login.onekalbe.com/KalbeProdLDAP1/?authn_try_count=0&contextType=external&username=string&contextValue=%2Foam&password=sercure_string&challenge_url=https%3A%2F%2Flogin.onekalbe.com%2FKalbeProdLDAP1%2F&ssoCookie=disablehttponly&request_id=4026290567095873144&locale=en_US&resource_url=https%253A%252F%252Fwww.onekalbe.com%252FAdmin%252FSSOLogin%253Freturnurl%253D%25252f#/login"
                          className="fa fa-user"
                        ></Link>
                      </li>
                      <li>
                        <div className="language-wrapper">
                          <span> <img src={languageIcon[formatMessage({ id: "language" })]} alt="" className="flag-icon"/></span>
                          {formatMessage({ id: "language" })}
                        </div>
                        <ul className="sub-menu">
                        <IntlContextConsumer>
                        {({ languages, language: currentLocale }) =>
                          languages.map(language => (
                            <li>
                              <a
                                key={language}
                                onClick={() => {changeLocale(language)}}
                              >
                                <span> <img src={languageIcon[languageName[language]]} alt={language} className="flag-icon"/></span>
                                {languageName[language]}
                              </a>
                            </li>
                            
                          ))
                        }
                      </IntlContextConsumer>
                        </ul>                       
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
        </header>
      </>
    )
  }
}
export default injectIntl(Header)
